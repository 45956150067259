//tablet
.tbdisp{display: block;}
.tbhide{display: none;}



#wrapper{
	min-width: 0;
}

#menu-btn{
	position: absolute;right:0;bottom:-2px;z-index: 999;
	width: 50px;height: 16px;transition:all 0.3s ease;
	cursor: pointer;
	.menu-line{
		display: block;width: 24px;height: 2px;
		&:nth-child(1){top:0;transition: top 0.1s $ease2 0.2s,transform 0.2s $ease2 0.05s;}
		&:nth-child(2){top:7px;transition: transform 0.1s $ease2 0s;}
		&:nth-child(3){top:14px;transition: top 0.1s $ease2 0.2s,transform 0.2s $ease2 0.05s;}
	}
}
.navi-wrap{
	display: block;visibility: hidden;opacity: 0;transition: all 0.5s $ease5;
	width: 100%;height: 100%;padding: 80px 0;background: rgba(0,0,0,0.7);backdrop-filter:blur(3px);
	overflow: auto;	-webkit-overflow-scrolling:touch;
	position: fixed;z-index: 998;top:0;left:0;
}
#gnavi{
	position: relative;z-index: 1;	padding: 0 8%;width: 100%;
}
#menu.open{
	#menu-btn{
		.menu-line{
			background: #fff !important;
			&:nth-child(1){top:7px;transform: rotate(-135deg);transition: top 0.1s $ease2 0.05s,transform 0.2s $ease2 0.1s;}
			&:nth-child(2){transform: scaleX(0);}
			&:nth-child(3){top:7px;transform: rotate(135deg);transition: top 0.1s $ease2 0.05s,transform 0.2s $ease2 0.1s;}
		}
	}
	.navi-wrap{visibility: visible;opacity: 1;}
}


#gnavi{
	width: 100%;
	left:0;top:50%;transform: translateY(-50%);
	path{
		fill:#fff !important;
	}
	.gnavi{
		&-list{
			width: 80%;margin: 0 auto;flex-wrap: wrap;
		}
		&-item{
			width: 45%;text-align: center;margin: 20px 0;
			&.active{
				&:before{opacity: 1;bottom:0;}
			}
		}
		
		&-top svg{width: calc(5.21% * 4);}
		&-about svg{width: calc(14.05% * 4);}
		&-gallery svg{width: calc(9.89% * 4);}
		&-factory svg{width: calc(12.03% * 4);}
		&-feature svg{width: calc(16.03% * 4);}
		&-recruit svg{width: calc(8.02% * 4);}
		&-company svg{width: calc(8.06% * 4);}
	}
	a{
		padding: 10px 0;
	}
}

.top-logo{
	&.is-move{
		left:50vw;top:25vh;
	}
	&.is-out{
		top:-50vh;
	}
}

.id-message{
	&:before{
		border-width: 32vh 43vw 42vh;
	}
	.message-box{
		top:auto;bottom:120px;right:50vw;transform: translateX(50%);
	}
}

.id-about{
	.about-box{
		width: 71vw;top:auto;bottom:60px;right:50vw;transform: translateX(50%);
	}
	.about-text{
		width: 100%;
	}
}

.id-gallery1{
	.gallery-box1{
		width: 50vw;height: calc((100vh - 55px) / 5 * 3);
		left:25vw;top: calc(((100vh - 55px) / 5) + 55px);
	}
	.gallery-logo{
		width: 240px;height: auto;
		img{@include max(w);top:-20px;}
		&:before{
			width: 308px;height: 105px;
		}
	}
	.gallery-text{
		width: 80%;text-align: left;left:10%;
	}
}
.id-gallery2{
	.gallery-box2{
		&:before{
			border: calc(25vw + 1px) solid rgba($BLACK,0.9);
			border-top-width: calc((100vh - 55px) / 5 + 56px);border-bottom-width: calc((100vh - 55px) / 5 + 1px);
		}
	}
	.gallery-atelier{
    top: calc(((100vh - 55px) / 5) * 4 + 75px);
	}
}
	
.id-factory1{
	height: auto;min-height: 100vh;
	&:before{display: none;}
	&:after{
		height: auto;top:calc((100vh - 55px) / 5 + 55px);
		background-image:none;
	}
	.factory1-box{
		width: 80vw;display: block;top:0;padding-top: calc((100vh - 55px) / 5 + 155px);padding-bottom: 100px;
	}
	.factory-left,.factory-right{width: 100%;}
	.factory-right{
		margin-top: 50px;
	}
	.factory-text{
		margin-top: 20px;
	}
}

.id-factory2{
	height: auto;min-height: 100vh;
	&:before{
		height: calc(((100vh - 55px) / 5) * 3 + 56px);
		background-image:none;
	}
	&:after{
		height: calc((100vh - 55px) / 5);
		background-image:none;
	}
	.factory2-box{
		width: 80vw;display: block;top:0;left:10vw;padding-top: 100px;padding-bottom: 100px;
		transform: none;
	}
	.factory-left,.factory-right{width: 100%;}
	.factory-right{
		margin-top: 50px;
	}
}

.id-feature1{
	height: auto;min-height: 100vh;
	&:before{
		height: calc(((100vh - 55px) / 5) * 4 + 56px);
		background-position: right -30vw center;
	}
	.feature1-box{
		width: 80vw;display: block;top:0;left:10vw;padding-top: 150px;padding-bottom: 100px;
		transform: none;
	}
	.feature-left,.feature-right{width: 100%;}
	.feature-right{
		margin-top: 50px;
	}
}
.id-feature2{
	height: auto;min-height: 100vh;
	&:before{
		height: calc(((100vh - 55px) / 5) * 3);
		background-position: right -30vw center;
	}
	&:after{
		content:"";width: 100%;height: calc(((100vh - 55px) / 5) * 1 + 56px);
		position: absolute;left:0;top:0;
		background: rgba(#000,0.9);
	}
	.feature2-box{
		width: 80vw;display: block;top:0;left:10vw;padding-top: calc(((100vh - 55px) / 5) * 2 + 156px);padding-bottom: 100px;
		transform: none;
	}
	.feature2-text{
		width: 100%;margin-top: 20px;
	}
}

.id-feature3{
	height: auto;min-height: 100vh;
	&:before{
		height: calc(((100vh - 55px) / 5) * 3 + 56px);
		background-position: right -30vw center;
	}
	&:after{
		height: calc((100vh - 55px) / 5);
	}
	.feature3-box{
		width: 80vw;display: block;left:10vw;top:0;padding-top: 150px;
		transform: none;
	}
	.feature3-textbox{
		width: 100%;margin-top: 20px;
	}
}
.id-recruit{
	height: auto;min-height: 100vh;
	.recruit-box{
		width: 80vw;
	}
	.recruit-inner{
		
	}
	.recruit-titbox{
		display: block;
	}
	.recruit-text{
		margin-top: 20px;width: 100%;
	}
	.recruit-require-tit{
		padding-left: 0;
	}
	.recruit-require-tb{
		margin-left: 0;
	}
	.recruit-bottom{
		display: block;
	}
	.recruit-status{
		width: 100%;padding-left: 62px;
	}
	.recruit-entry{
		width: 100%;margin-top: 15px;
	}
}

.id-company{
	.company-box{
		
	}
	.company-inner{
		width: 80vw;
	}
	.company-info{
		display: block;
	}
	.company-tb{
		width: 100%;
	}
	.company-access{
		width: 100%;
	}
	.company-map{
		width: 400px;padding-left: 84px;text-align: left;
	}
	.company-cake-line{
		display: none;
	}
}


.bg2{
	grid-template-rows:1fr 1fr 1fr 1fr 1fr;
	grid-template-columns:1fr 1fr 1fr 1fr;
}
.bg3{
	grid-template-rows:1fr 1fr 1fr 1fr 1fr;
	grid-template-columns:1fr 1fr 1fr 1fr;
}
.bg4{
	grid-template-rows:1fr 1fr 1fr 1fr 1fr;
	grid-template-columns:1fr 1fr 1fr 1fr;
}

.cake-line{
	svg{
		left: auto;right:-30vw;transform: translateX(0);
	}
}

