//page
$glow-time:2.5s;
$glow-delay:0.8s;
.id-top{
	height: 100vh;position: relative;
	&:before{
		content:"";width: 100%;height: 100%;background: rgba($BLACK,0.9);
		position: absolute;left:0;top:0;z-index: 2;
	}
}
.top-logo{
	position: fixed;top:50vh;left:50vw;transform: translate(-50%,-50%);
	transition: all 1.5s $ease9;z-index: 101;
	width: 353px;height: 63px;
	&:before,&:after{
		content:"";width: 445px;height: 154px;
		background: url(../images/logo_glow.png) center center no-repeat;background-size:cover;
		position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);opacity: 0;
		
	}
	img{
		position: absolute;left:0;top:0;
	}
	.top-logo-color{opacity: 0;}
	&.is-move{
		left:20%;
	}
	&.is-glow{
		&:after{
			animation: top-logo-glow $glow-time ease-in-out 0s 1 normal both;
		}
	}
	&.is-color{
		.top-logo-white{opacity: 0;}
		.top-logo-color{opacity: 1;}
	}
	&.is-out{
		top:-50vh;transition: all 0.6s $swing;
	}
}
.loading-cover{
	width: 100vw;height: 100vh;background: $BLACK;
	position: fixed;top:0;left:0;z-index: 100;
	transition:all 0.5s $ease5 0.4s;
}

body.is-loaded{
	.top-logo{
		&:before{
			animation: top-logo-glow $glow-time ease-in-out $glow-delay 1 normal both;
		}
	}
	.cake-line{
		animation: top-line-glow $glow-time ease-in-out $glow-delay 1 normal both;
		path{
			animation: top-path-glow $glow-time ease-in-out $glow-delay 1 normal both;
		}
	}
	.loading-cover{
		opacity: 0;visibility: hidden;
	}
}

.id-message{
	height: 100vh;position: relative;
	&:before{
		content:"";width: 100%;height: 100%;
		position: absolute;left:0;top:0;z-index: 2;
		border: 10vh solid rgba($BLACK,0.9);border-width: 14.5vh 44.9vw;
		transition:border 0.3s linear;
	}
	.message-box{
		opacity: 0;position: absolute;right:18vw;top:50vh;transform: translateY(-50%); z-index: 4;
		transition: opacity 0.5s linear 1s;
		&.is-show{
			opacity: 1;
			.message-jp1{animation:message-jp1 3s $ease9 1.5s 1 normal both;	}
			.message-jp2{animation:message-jp1 3s $ease9 2.5s 1 normal both;	}
			.message-jp3{animation:message-jp1 3s $ease9 3.5s 1 normal both;	}
		}
		
	}
	.message-jp{
		span{
			position: absolute;top:0;left:0;opacity: 0;
		}
		.message-jp1{
			top:-82px;left:39px;
		}
		.message-jp2{
			top:10px;left:110px;
		}
		.message-jp3{
			top:89px;left:76px;
		}
	}
	&.about{
		&:before{
			position: fixed;border-color: rgba(255,255,255,0.9);
		}
	}
	&.gallery{
		&:before{
			position: absolute;top:100vh;
		}
	}
}
.id-about{
	height: 100vh;position: relative;z-index: 2;
	.about-box{
		width: 370px;position: absolute;top:50vh;right:10vw;transform: translateY(-50%);
	}
	.about-tit{
		font-size: 2rem;letter-spacing: 0;
	}
	.about-tit-en{
		margin-top: 15px;
	}
	.about-text{
		width: 340px;font-size: 1.2rem;margin-top: 15px;text-align: justify;
	}
}

.id-gallery1{
	height: 100vh;position: relative;
	.gallery-box1{
		width: 60vw;height: calc((100vh - 55px) / 2);background: rgba($BLACK,0.9);
		position: absolute;left:20vw;top:calc(((100vh - 55px) / 4) + 55px);z-index: 2;
	}
	.gallery-logo{
		width: 353px;height: 63px;
		position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);
		img{
			position: absolute;z-index: 2;left:0;top:0;
		}
		&:before{
			content:"";width: 445px;height: 154px;
			background: url(../images/logo_shadow.png) center center no-repeat;background-size:cover;
			position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);opacity: 0;z-index: 1;
			transition:all 0.3s linear;
		}
		&.is-shadow{
			position: fixed;left:50vw;top:calc(50vh + 27px);
			&:before{
				opacity: 1;
			}
		}
		&.is-out{
			position: absolute;left:50%;top:calc(100vh + 50%);
		}
	}
	.gallery-text{
		color: #fff;text-align: center;width: 100%;font-size: 1.4rem;
		position: absolute;bottom:20px;left:0;
	}
}
.id-gallery2{
	height: 100vh;position: relative;
	.gallery-box2{
		width: 100vw;height: 100vh;
		position: absolute;left:0;top:0;
		&:before{
			content:"";width: 100%;height: 100%;
			border: calc(20vw + 1px) solid rgba($BLACK,0.9);
			border-top-width: calc((100vh - 55px) / 4 + 56px);border-bottom-width: calc((100vh - 55px) / 4 + 1px);
			position: absolute;top:0;left:0;z-index: 1;
		}
	}
	.gallery-atelier{
		position: absolute;left:50%;top:calc(((100vh - 55px) / 4) * 3 + 75px);transform: translateX(-50%);z-index: 2;
	}
}
.id-factory1{
	height: 100vh;position: relative;
	&:before,&:after{
		content:"";width: 100%;background: url(../images/factory_bg.png) center top no-repeat rgba(#000,0.9);background-size:1280px 720px;
		position: absolute;left:0;z-index: 2;
	}
	&:before{
		height: calc((100vh - 55px) / 4 + 56px);top:0;
	}
	&:after{
		height: calc((100vh - 55px) / 2 + 1px);bottom:0;background-position: center bottom;
	}
	.factory1-box{
		width: 850px;display: flex;justify-content: space-between;margin: 0 auto;color: #fff;
		position: relative;z-index: 3;top:calc(((100vh - 55px) / 4) - 25px);
	}
	.factory-left{
		width: 360px;
	}
	.factory-right{
		width: 360px;
	}
	.factory-tit{
		font-size: 1.8rem;margin-top: 10px;
	}
	.factory-text{
		margin-top: calc(((100vh - 55px) / 4) + 60px);text-align: justify;
	}
	.factory-jfsb{
		display: flex;font-size: 1.2rem;justify-content: flex-end;
	}
	.factory-jfsb-logo{
		width: 45px;
	}
}
.id-factory2{
	height: 100vh;position: relative;
	&:before,&:after{
		content:"";width: 100%;background: url(../images/factory_bg.png) center top no-repeat rgba(#000,0.9);background-size:1280px 720px;
		position: absolute;left:0;z-index: 2;
	}
	&:before{
		height: calc((100vh - 55px) / 2 + 56px);top:0;
	}
	&:after{
		height: calc((100vh - 55px) / 4 + 1px);bottom:0;background-position: center bottom;
	}
	.factory2-box{
		width: 850px;display: flex;justify-content: space-between;color: #fff;
		position: relative;z-index: 3;left:50%;top:calc((100vh - ((100vh - 55px) / 2)) / 2);transform: translate(-50%,-50%);
	}
	.factory-left{
		width: 360px;
	}
	.factory-right{
		width: 360px;
	}
	.factory-tit{
		font-size: 1.8rem;margin-top: 10px;
	}
	.factory-text{
		margin-top: 20px;text-align: justify;
	}
	
}
.id-feature1{
	height: 100vh;position: relative;
	&:before{
		content:"";width: 100%;height: calc(((100vh - 55px) / 4) * 3 + 56px);
		position: absolute; top:0;left:0;z-index: 1;
		background: url(../images/feature1_cake_line.png) center center no-repeat rgba(#000,0.9);background-size:cover;
	}
	
	.feature1-box{
		width: 860px;display: flex;justify-content: space-between;color: #fff;
		position: absolute;z-index: 3;left:50%;top:calc((100vh - ((100vh - 55px) / 4)) / 2);transform: translate(-50%,-50%);
		
	}
	.feature-left{
		width: 363px;
	}
	.feature-right{
		width: 363px;
		.feature1-subtit{
			white-space: nowrap;
		}
	}
	.feature1-tit{
		
	}
	.feature1-subtit{
		font-size: 1.8rem;margin-top: 10px;
	}
	.feature1-text{
		margin-top: 20px;text-align: justify;
	}
	.feature1-en{
		position: absolute;z-index: 2;
		&.production{
			left:0;top:93px;
		}
		&.development{
			right:0;bottom:calc((100vh - 55px) / 4 + 80px);
		}
	}
}
.id-feature2{
	height: 100vh;position: relative;
	&:before{
		content:"";width: 100%;height: calc(((100vh - 55px) / 4) * 3 + 1px);
		position: absolute; bottom:0;left:0;z-index: 1;
		background: url(../images/feature2_cake_line.png) center center no-repeat rgba(#000,0.9);background-size:cover;
	}
	.feature2-box{
		width: 750px;display: flex;justify-content: space-between;align-items: center;color: #fff;
		position: absolute;z-index: 3;left:50%;top:calc((((100% - 55px) / 4) * 3) / 2 + ((100% - 55px) / 4) + 55px);transform: translate(-50%,-50%);
	}
	.feature2-titbox{
		width: 360px;
	}
	.feature2-subtit{
		font-size: 1.8rem;margin-top: 10px;
	}
	.feature2-text{
		width: 340px;text-align: justify;
	}
	.feature2-en{
		position: absolute;z-index: 2;left:29vw;bottom:10vh;
	}
}
.id-feature3{
	height: 100vh;position: relative;
	&:before,&:after{
		content:"";width: 100%;background: rgba(#ed6d3d,0.9);
		position: absolute;left:0;z-index: 2;
	}
	&:before{
		height: calc(((100vh - 55px) / 2) + 55px);top:0;
	}
	&:after{
		height: calc(((100vh - 55px) / 4));bottom:0;
	}
	.feature3-box{
		width: 740px;display: flex;justify-content: space-between;
		position: absolute;z-index: 4;left:50%;top:calc(((100% - 55px) / 2) / 2 + 55px);transform: translate(-50%,-50%);
	}
	.feature3-titbox{
		width: 350px;
	}
	.feature3-textbox{
		width: 340px;
	}
	.feature3-subtit{
		font-size: 1.8rem;margin-top: 10px;
	}
	.feature-banner{
		width: 312px;text-align: center;margin-top: 10px;
		a{
			display: block;
			&:hover{
				opacity: 0.7;
			}
		}
	}
	.feature-banner-label{
		font-size: 2rem;display: block;margin-bottom: 5px;font-weight: 500;
	}
	.feature3-text{
		text-align: justify;
	}
	.feature3-list{
		font-size: 1.4rem;margin-top: 20px;font-weight: 500;
		li{
			&:before{content:"・"}
		}
	}
	
	.feature3-en{
		position: absolute;right:14vw;bottom:12vh;z-index: 5;
	}
	
	.feature3-cake-line{
		width: 100vw;height: calc(((100vh - 55px) / 4) * 3);
		background: url(../images/feature3_cake_line.png) center center no-repeat;background-size:cover;
		position: absolute;left:0;top:55px;z-index: 3;
	}
	
}

.id-recruit{
	height: 100vh;position: relative;
	.recruit-box{
		width: 770px;height: calc(100vh - 55px);margin: 0 auto;background: rgba(255,255,255,0.6);
		position: relative;z-index: 3;top:55px;
	}
	.recruit-inner{
		width: 100%;background: rgba(255,255,255,0.8);padding: 25px;
		position: absolute;left:0;top:50%;transform: translateY(-50%);
	}
	.recruit-titbox{
		display: flex;
	}
	.recruit-tit{
		width: 105px;
	}
	.recruit-text{
		width: calc(100% - 105px);letter-spacing: 0.05em;text-align: justify;
	}
	.recruit-require{
		display: flex;margin-top: 20px;flex-wrap: wrap;
		letter-spacing: 0.05em;
	}
	.recruit-require-tit{
		font-weight: bold;font-size: 1.2rem;width: 100%;padding-left: 13px;line-height: 1.4;
	}
	.recruit-require-tb{
		width: 238px;font-size: 1.2rem;margin-left: 13px;line-height: 1.4;margin-top: 5px;
		tbody th{width: 62px;}
		.ls1em{letter-spacing: 1em;}
		&.recruit-production{border-right: 1px solid $BLACK;}
		&.recruit-sales{margin-left: 30px;}
		&.recruit-method{width: 100%;margin-top: 10px;}
	}
	.recruit-bottom{
		display: flex;justify-content: space-between;margin-top: 15px;align-items: center;
	}
	.recruit-status{
		width: 388px;background: #BFBFBF;color: #656464;padding: 6px 30px 6px 75px;line-height: 1;
	}
	.recruit-entry{
		width: calc(100% - 400px);padding-left: 45px;letter-spacing: 0.03em;
		position: relative;
	}
	.recruit-entry-tit{
		position: absolute;left:0;top:0;
	}
	.recruit-en{
		position: absolute;left:10vw;bottom:10vh;z-index: 	4;
	}
	.recruit-cake-line{
		width: 100vw;height: calc(100vh - 55px);
		position: absolute;bottom:0;left:0;z-index: 2;
		background: url(../images/recruit_cake_line.png) center center no-repeat;background-size:cover;
	}
}

.id-company{
	height: 100vh;position: relative;
	.company-box{
		width: 100vw;background: rgba(255,255,255,0.6);
		position: relative;z-index: 3;top:calc(50vh + 27px);transform: translateY(-50%);
	}
	.company-inner{
		width: 770px;margin: 0 auto;
		background: rgba(255,255,255,0.8);padding: 25px;
	}
	
	.company-info{
		display: flex;justify-content: space-between;margin-top: 10px;
	}
	.company-tb{
		width: 350px;font-size: 1.1rem;letter-spacing: 0.05em;
		th,td{padding-top: 5px;padding-bottom: 5px;}
		tbody th{width: 64px;text-align: right;}
		tbody td{padding-left: 20px;}
	}
	.company-address{
		display: flex;flex-wrap: wrap;
		&-label{
			width: 58px;
		}
		&-text{
			width: calc(100% - 58px);
		}
	}
	
	.company-access{
		width: 350px;
		.company-tb{

		}
	}
	.company-map{
		width: 100%;text-align: center;position: relative;margin-top: 20px;
		img{margin: 0 auto;}
	}
	.btn-map{
		display: block;width: 124px;background: #BFBFBF;color: #404040;font-size: 1.1rem;line-height: 1;letter-spacing: 0;text-align: left;
		padding: 4px 0 4px 6px;border-radius: 3px;
		position: absolute;right:30px;bottom:3px;
		transition:opacity 0.3s linear;
		img{
			position: absolute;right:3px;top:calc(50% - 6px)
		}
		&:hover{
			opacity: 0.7;text-decoration: none;
		}
	}
	.company-en{
		position: absolute;right:4vw;bottom:6vh;z-index: 	4;
	}
	.company-cake-line{
		width: 100vw;height: calc(100vh - 55px);
		position: absolute;bottom:0;left:0;z-index: 2;
		background: url(../images/recruit_cake_line.png) center center no-repeat;background-size:cover;
	}
}

.bg1{
	width: 100vw;height: calc(100vh - 37px);
	position: fixed;top:37px;left:0;z-index: 1;
	img{
		position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);
		width: 100%;height: 100%;
		object-fit: cover;
	}
	&.gallery{
		position: absolute;top:calc(200vh + 37px);
	}
}

.bg2{
	display: grid;grid-template-rows: 1fr 1fr 1fr 1fr;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	width: 100vw;height: calc(100vh - 55px);
	position: absolute;left:0;top:55px;z-index: 1;
	&-photo{
		line-height: 1;	position: relative;overflow: hidden;
		img{
			position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);
			width: 100.5%;height: 100.5%;
			object-fit: cover;
		}
	}
	&.gallery2{
		position: fixed;
	}
	&.factory1{
		position: absolute;top:calc(100vh + 55px);
	}
}
.bg3{
	display: grid;grid-template-rows: 1fr 1fr 1fr 1fr;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	width: 100vw;height: calc(100vh - 55px);
	position: absolute;left:0;top:55px;
	&-photo{
		line-height: 1;	position: relative;overflow: hidden;
		img{
			position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);
			width: 100.5%;height: 100.5%;
			object-fit: cover;
		}
	}
	&.factory2{
		position: fixed;
	}
	&.feature3{
		position: absolute;top:calc(300vh + 55px);
	}
}
.bg4{
	display: grid;grid-template-rows: 1fr 1fr 1fr 1fr;grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
	width: 100vw;height: calc(100vh - 55px);
	position: absolute;left:0;top:55px;
	&-photo{
		line-height: 1;	position: relative;overflow: hidden;
		img{
			position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);
			width: 100.5%;height: 100.5%;
			object-fit: cover;
		}
	}
	
}
.bg5{
	display: grid;grid-template-rows: 1fr 1fr;grid-template-columns: 1fr 1fr;
	width: 100vw;height: calc(100vh - 55px);
	position: absolute;left:0;top:55px;
	&-photo{
		line-height: 1;	position: relative;overflow: hidden;
		img{
			position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);
			width: 100.5%;height: 100.5%;
			object-fit: cover;
		}
	}
}
.bg6{
	display: grid;grid-template-rows: 1fr 1fr;grid-template-columns: 1fr 1fr;
	width: 100vw;height: calc(100vh - 55px);
	position: absolute;left:0;top:55px;
	&-photo{
		line-height: 1;	position: relative;overflow: hidden;
		img{
			position: absolute;left:50%;top:50%;transform: translate(-50%,-50%);
			width: 100.5%;height: 100.5%;
			object-fit: cover;
		}
	}
}
.cake-line{
	height: 100vh;width:100%;
	position: fixed;top:0;left:0;z-index: 100;
	filter: drop-shadow(0px 0px 0 #000);
	opacity: 0;visibility: hidden;
	transition: all 0.5s linear;
	svg{
		height: 100%;width: auto;position: absolute;
		left: 50%;top:0;transform: translateX(-50%);
	}
	path{
		fill:#595959;
	}
	&.is-show{
		opacity: 1;visibility: visible;
	}
}

