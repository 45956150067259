//animation

@keyframes top-logo-glow{
	0%{opacity: 0;}
	50%{opacity: 1;}
	100%{opacity: 0;}
}

@keyframes top-line-glow{
	0%{filter: drop-shadow(0 0 0 #000);}
	50%{filter: drop-shadow(0 0 4px #fff);}
	100%{filter: drop-shadow(0 0 0 #000);}
}

@keyframes top-path-glow{
	0%{fill:#595959}
	50%{fill:#999;}
	100%{fill:#595959}
}

@keyframes message-jp1{
	0%{opacity: 0;}
	100%{opacity: 1;}
}