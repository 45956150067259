//mobile


.spdisp{display: block;}
.sphide{display: none;}


.logo{
	left:20px;
	img{width: 86px;height: 25px;}
}


header.is-expand{
	.logo{top:16px;}
	#menu-btn{bottom:20px;}
}
#gnavi{
	.gnavi{
		&-list{
			width: 90%;margin: 0 auto;flex-wrap: wrap;
		}
		&-top svg{width: calc(5.21% * 6);}
		&-about svg{width: calc(14.05% * 6);}
		&-gallery svg{width: calc(9.89% * 6);}
		&-factory svg{width: calc(12.03% * 6);}
		&-feature svg{width: calc(16.03% * 6);}
		&-recruit svg{width: calc(8.02% * 6);}
		&-company svg{width: calc(8.06% * 6);}
	}
	a{
		padding: 10px 0;
	}
}

.id-top{

}
.top-logo{
	width: 240px;height: auto;
	img{@include max(w);top:-20px;}
	&:before,&:after{
		width: 308px;height: 105px;
	}

	&.is-out{
		top:0;
	}
	
}
.id-message{
	height: 1012px;
	&:before{
		height: 1012px;border-width: 230px 41vw 520px;
	}
	&.about:before{
		border-color:rgba(35,24,21,.9);
	}
	&.about-sp:before{
		border-color:rgba(#fff,.9);
	}
	&.gallery:before{
		top:1012px;
	}
	.message-box{
		
	}
	.message-jp{
		span{
			transform: scale(0.7);transform-origin: left center;
		}
		.message-jp1{
			left:30px;top:-50px;
		}
		.message-jp2{
			left:-10px;top:15px;
		}
		.message-jp3{
			left:40px;top:80px;
		}
		
	}
}
.id-about{
	height: auto;min-height: 0;
	.about-box{
		width: 80vw;position: relative;left:0;bottom:0;z-index: 2;
		transform: none;margin: 0 auto;padding-top: 540px;padding-bottom: 0;
	}
	&:after{
		//content:"";width: 100vw;height:22.5px;
		background: rgba(255,255,255,0.9);
		position: absolute;left:0;bottom:-55px;z-index: 1;
	}
}

.id-gallery1{
	height: auto;min-height: 100vh;z-index: 4;
	.gallery-box1{
		width: 100vw;left:0;
	}
}
.id-gallery2{
	z-index: 5;
	.gallery-box2{
		&:before{
			border-left: none;border-right: none;
		}
		&:after{
			content:"";background: rgba(35,24,21,.9);width: 100%;height: 55px;
			position: absolute;bottom:-55px;left:0;
		}
	}
}

.id-factory1{
	z-index: 6;
	&:before,&:after{
		display: none;
	}
	.factory1-box{
		width: 100vw;padding: 0;	height: auto;padding-top: calc(((100vh - 55px) / 5 + 55px);
	}
	.factory-left{
		height: calc(((100vh - 55px) / 5) * 3);
		background: rgba(0,0,0,0.9);padding: 0 8vw;
		display: flex;flex-direction: column;justify-content: center;
	}
	.factory-right{
		height: calc(((100vh - 55px) / 5) * 3);
		background: rgba(0,0,0,0.9);padding: 0 8vw;margin-top: calc(((100vh - 55px) / 5);
		display: flex;flex-direction: column;justify-content: center;
	}
	.factory-text{
		margin-top: 15px;
	}
}
.id-factory2{
	z-index: 7;
	&:before,&:after{
		display: none;
	}
	.factory2-box{
		width: 100vw;padding-top: calc(((100vh - 55px) / 5);left:0;
	}
	.factory-left{
		height: calc(((100vh - 55px) / 5) * 3);margin-top: 0;
		background: rgba(0,0,0,0.9);padding: 0 8vw;
		display: flex;flex-direction: column;justify-content: center;
	}
	.factory-right{
		height: calc(((100vh - 55px) / 5) * 3);
		background: rgba(0,0,0,0.9);padding: 0 8vw;margin-top: calc((100vh - 55px) / 5 );
		display: flex;flex-direction: column;justify-content: center;
	}
}

.id-feature1{
	z-index: 8;
	&:before{
		display: none;
	}
	.feature1-box{
		width: 100vw;padding: 0;left:0;position: relative;z-index: 1;
	}
	.feature-left{
		height: calc(((100vh - 55px) / 5) * 4);
		background: rgba(0,0,0,0.9);padding: 40px 8vw 0;
		display: flex;flex-direction: column;justify-content: center;
	}
	.feature-right{
		height: calc(((100vh - 55px) / 5) * 4);margin-bottom: calc((100vh - 55px) / 5 );
		background: rgba(0,0,0,0.9);padding: 0 8vw 40px;margin-top: calc((100vh - 55px) / 5 );
		display: flex;flex-direction: column;justify-content: center;
	}
	.feature1-en{
		transform: scale(0.6);
		&.production{
			top:40px;left:20px;transform-origin: left center;
		}
		&.development{
			bottom:40px;right:20px;transform-origin: right center;
		}
	}
}

.id-feature2{
	z-index: 9;
	&:before,&:after{display: none;}
	.feature2-box{
		width: 100vw;padding:0 8vw 60px;left:0;position: relative;z-index: 1;
		background: rgba(0,0,0,0.9);
		height: calc(((100vh - 55px) / 5) * 4 + 55px);
		display: flex;flex-direction: column;justify-content: center;
	}
	.feature2-titbox{
		width: 100%;
	}
	.feature2-en{
		bottom: calc((100vh - 55px) / 5 + 50px);left:auto;right:20px;
		transform: scale(0.7);transform-origin: right center;
	}
}

.id-feature3{
	z-index: 10;height: auto;min-height: 0;padding-top: calc((100vh - 55px) / 5);padding-bottom: calc((100vh - 55px) / 5);
	&:before,&:after{display: none;}
	.feature3-box{
		width: 100vw;padding:0 8vw;left:0;position: relative;z-index: 1;
		height: calc(((100vh - 55px) / 5) * 4);padding-bottom: 20px;
		background: rgba(237,109,61,.9);
		display: flex;flex-direction: column;justify-content: center;
	}
	.feature3-titbox{
		width: 100%;
	}
	.feature3-subtit{
		white-space: nowrap;
	}
	.feature3-cake-line{
		display: none;
	}
	.feature-banner{
		width: 60vw;margin: 	10px auto 0;
		img{
			@include max(w);
		}
	}
	.feature-banner-label{
		font-size: 1.6rem;
	}
	.feature3-en{
		bottom: calc((100vh - 55px) / 5 + 20px);left:auto;right:20px;
		transform: scale(0.7);transform-origin: right center;
	}
}

.id-recruit{
	z-index: 11;height: auto;min-height:0;
	.recruit-box{
		width: 100%;top:0;height: auto;
	}
	.recruit-inner{
		position: static;transform: none;padding: 60px 8vw 100px;
		background: rgba(#fff,0.55);
	}
	
	.recruit-require-tb{
		width: 100%;margin-top: 15px;
		th,td{
			padding: 5px 0;
		}
		&.recruit-production{
			border: none;border-bottom: 1px dashed $BLACK;padding-bottom: 10px;
			tr:last-child{
				th,td{padding-bottom: 20px;}
			}
		}
		&.recruit-sales{
			margin-left: 0;
		}
	}
	.recruit-en{
		transform: scale(0.7);transform-origin: left center;
		left:20px;bottom:40px;
	}
}

.id-company{
	z-index: 12;height: auto;min-height:0;
	.company-box{
		width: 100%;top:0;transform: none;
	}
	.company-inner{
		width: 100%;position: static;transform: none;padding: 60px 8vw 100px;
		background: rgba(#fff,0.55);
	}
	.company-en{
		transform: scale(0.7);transform-origin: right center;
		right:20px;bottom:25px;
	}
	.company-tb{
		tbody td{
			padding-left: 0;
		}
		tbody th{
			text-align: left;width: 80px;
		}
	}
	.company-address{
		display: table-cell;
		.company-address-label{
			display: block;margin-top: 10px;
			&:first-child{margin-top: 0;}
		}
	}
	.company-map{
		width: 100%;
		padding-left: 0;
	}
}
.bg1{
	&.gallery{
		position: fixed;top:37px
	}
	&.sp-gallery{
		opacity: 0;
	}
}
.bg2{
	&.factory1{
		position: fixed;top:55px;
	}
	&.sp-factory{
		opacity: 0;
	}
}
.bg3{
	&.feature3{
		position: fixed;top:55px;
	}
}
.bg4{
	top:0;
	&.sp-feature3{
		position: fixed;top:55px;
	}
}
.bg5{
	top:0;
	&.sp-recruit{
		position: fixed;top:55px;
	}
}
.bg6{
	top:0;
	&.sp-company{
		position: fixed;top:55px;
	}
}