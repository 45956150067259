//layout
body{

}

// ラッパー
#wrapper{
	width: 100%;background: $BLACK;overflow: hidden;min-width: 1000px;
}

// ヘッダー
header{
	width: 100%;
	height: 37px;
	position: fixed;top:0;left:0;z-index: 1000;
	transition:all 0.3s ease;
	&.is-white{
		background: #fff;
		.logo-white,.logo-white-color{opacity: 0;}
		.logo-color{opacity: 1;}
		
		#menu-btn{
			.menu-line{background: $BLACK;}
		}
		#gnavi{
			path{fill:$BLACK;}
			.gnavi-item.active:before{background: $BLACK;}
		}
	}
	&.is-expand{
		height: 55px;background: #000;
		.logo{
			top:13px;
		}
		.logo-white,.logo-color{opacity: 0;}
		.logo-white-color{opacity: 1;}
		#menu-btn{
			bottom:17px;
			.menu-line{background: #fff;}
		}
		#gnavi{
			bottom:22px;
			path{
				fill:#fff;
			}
			.gnavi-item.active:before{background: #fff;}
		}
	}
}

.logo{
	position: fixed;top:56px;left:36px;transition:all 0.3s ease;
	img{
		position: absolute;left:0;top:0;
	}
	.logo-color,.logo-white-color{opacity: 0;}
}

// グローバルナビ
.navi-wrap{
	
}

#menu-btn{
	position: absolute;right:33px;bottom:-2px;
	width: 8px;height: 16px;transition:all 0.3s ease;
	.menu-line{
		display: block;width: 8px;height: 2px;
		background: #fff;
		position: absolute;left:0;
		&:nth-child(1){top:0;}
		&:nth-child(2){top:7px;}
		&:nth-child(3){top:14px}
	}
}

#gnavi{
	position: absolute;
	right:70px;bottom:3px;transition:all 0.3s ease;
	path{
		fill:#fff;
	}
	
	.gnavi{
		&-list{
			display: flex;justify-content: space-between;width: 622px;
		}
		&-item{
			line-height: 1;position: relative;
			&:before{
				content:"";height: 1px;width: 100%;background: #fff;
				position: absolute;bottom:-12px;left:0;opacity: 0;
				transition:all 0.2s ease;
			}
			&.active{
				&:before{opacity: 1;bottom:-3px;}
			}
		}
		&-top{width: 5.21%;}
		&-about{width: 14.05%;}
		&-gallery{width: 9.89%;}
		&-factory{width: 12.03%;}
		&-feature{width: 16.03%;}
		&-recruit{width: 8.02%;}
		&-company{width: 8.06%;}
	}
	a{
		display: block;transition:opacity 0.3s linear;
		&:hover{
			opacity: 0.7;
		}
	}
}

article{
	position: relative;z-index: 2;
}


// サイドバー 
.side{

}

// フッター
footer{

}
.copymark{
	font-family: Verdana,"Droid Sans";
}